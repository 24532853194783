/*
 * @Author: hexujie
 * @Date: 2020-05-12 16:54:48
 * @LastEditTime: 2020-09-21 18:51:44
 * @LastEditors: yangbing70
 * @Description:
 * @FilePath: /Nirvana/src/pages/home/service.js
 */
import request from '@/utils/request';

/**
 * @description: 标签数量查询
 */
export const tagCount = async (params) => {
  return request('/megatron/api/v1/order/tag/count', {
    method: 'GET',
    params,
  });
};
/**
 * @description: 马上装上线提示
 * 不用公告原因，公告中无类型判断，无法完成弹窗只显示一次功能
 */
export const installNowGuideCheck = async (params) => {
  return request('/hound/api/v1/shop/ext', {
    method: 'GET',
    params,
  });
};
/**
 * @description: 门店公告弹窗
 */
export const notice = async (params) => {
  return request('/hound/api/v1/notice', {
    method: 'GET',
    params,
  });
};
/**
 * @description: 门店到期提醒弹窗
 */
export const remind = async (params) => {
  return request('/hound/api/v1/expire/remind', {
    method: 'GET',
    params,
  });
};
/**
 * @description: 核销码查询
 */
export const consumeCodeCheck = async (params) => {
  return request('/megatron/api/v1/consumecode/check', {
    method: 'GET',
    params,
  });
};
/**
 * @description: 热区数量查询
 */
export const hotCount = async (params) => {
  return request('/megatron/api/v1/order/hot/count', {
    method: 'GET',
    params,
  });
};
/**
 * @description: 未完结工单查询
 */
export const processingList = async (params) => {
  return request('/megatron/api/v1/order/processing/list', {
    method: 'GET',
    params,
  });
};
/**
 * @description: 今日预约到店查询
 */
export const appointList = async (params) => {
  return request('/megatron/api/v1/appoint/today/list', {
    method: 'GET',
    params,
  });
};
/**
 * @description: 待核销服务列表查询
 */
export const unconsumedList = async (params) => {
  return request('/megatron/api/v1/unconsumed/list', {
    method: 'GET',
    params,
  });
};
/**
 * @description: 查询状态码列表
 */
export const statusList = async (params) => {
  return request('/megatron/api/v1/status/list', {
    method: 'GET',
    params,
  });
};
/**
 * @description: 保存预约信息
 */
export const saveAppoint = async (params) => {
  return request('/megatron/api/v1/appoint/save', {
    method: 'POST',
    data: params,
  });
};
/**
 * @description: 查询预约单列表
 */
export const reservationList = async (params) => {
  return request('/megatron/api/v1/reservation/list', {
    method: 'GET',
    params,
  });
};
/**
 * @description: 查询待核销服务
 */
export const customerService = async (params) => {
  return request('/megatron/api/v1/customer/service', {
    method: 'GET',
    params,
  });
};
/**
 * @description: 核销码开单校验
 */
export const customerServiceCheck = async (params) => {
  return request('/megatron/api/v1/consumecode/check/order', {
    method: 'GET',
    params,
  });
};
/**
 * @description: 主账号绑定状态查询
 */
export const acountBindingStatus = async () => {
  // return {
  //   data: {
  //     mainMappingStatus: false,
  //     subMappingStatus: false,
  //   },
  // };
  return request('/hound/api/v1/account/mapping/status', {
    method: 'POST',
  });
};
/**
 * @description: 子账号绑定状态查询
 */
export const subBindStatus = async () => {
  // return {
  //   status: true,
  //   timeout: 10000,
  //   isShowErrMsg: false,
  //   error: {
  //     code: 404,
  //     msg: '["18507835380   密码错误","18507835381   密码错误","18507835382   密码错误"]',
  //   },
  // };
  return request('/hound/api/v1/account/sub/mapping', {
    method: 'POST',
  });
};



