/*
 * @Author: hexujie
 * @Date: 2020-07-05 16:59:50
 * @LastEditTime: 2020-08-28 18:49:20
 * @LastEditors: hexujie
 * @Description: 
 * @FilePath: /Nirvana/src/pages/finance/orderPayment/models/pay.js
 */
import { getCouponList, getWashCouponList, customDiscount } from '../service';

const pay = {
  namespace: 'pay',
  state: {
    couponInfo: {},
    otherPhoneNumber: '',
    canUseOtherCoupon: false,
    selectedCard: {},
    customerMobile: '', // 当前订单的客户电话
  },
  effects: {
    * getCouponListRequest({ payload }, { call, put }) {
      const { status, data } = yield call(payload.orderId ? getCouponList : getWashCouponList, payload);
      
      if (status) {
        yield put({
          type: 'updateCouponInfo',
          payload: data,
        });
      }

      return { status, data };
    },
    * canUseOtherCoupon({ payload }, { call, put }) {
      const { status, data } = yield call(customDiscount, payload);
      
      if (status) {
        yield put({
          type: 'updateCanUseOtherCoupon',
          payload: data,
        });
      }
    },
  },
  reducers: {
    updateCouponInfo(state, { payload }) {
      return {
        ...state,
        couponInfo: payload,
      };
    },
    updateOtherPhoneNumber(state, { payload }) {
      return {
        ...state,
        otherPhoneNumber: payload,
      };
    },
    updateCanUseOtherCoupon(state, { payload }) {
      return {
        ...state,
        canUseOtherCoupon: payload,
      };
    },
    updateSelectedCard(state, { payload }) {
      return {
        ...state,
        selectedCard: {
          ...state.selectedCard,
          ...payload,
        },
      };
    },
    updateUsedCard(state, { payload }) {
      return {
        ...state,
        usedCard: payload,
      };
    },
    // 更新当前订单的客户电话
    updateCustomerMobile(state, { payload }) {
      return {
        ...state,
        customerMobile: payload,
      };
    },
    // 清空store
    clearState(state) {
      return {
        ...state,
        otherPhoneNumber: '',
        couponInfo: {},
        selectedCard: {},
      };
    },
  },
};

export default pay;
