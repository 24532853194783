export default {
  namespace: 'carDetail',
  state: {
    precheckServiceList: [],
    maintainServiceList: [],
    maintainParameter: {},
  },
  effects: {},
  reducers: {
    savePrecheckServiceList(state, { payload }) {
      return {
        ...state,
        precheckServiceList: payload,
      };
    },
    saveMaitainServiceList(state, { payload }) {
      return {
        ...state,
        maintainServiceList: payload,
      };
    },
    deleteService(state, { payload }) {
      const {
        targetId, type,
      } = payload;
      let target;
      if (type === 'precheck') {
        target = { precheckServiceList: state.precheckServiceList.filter(({ id }) => id !== targetId) };
      } else {
        target = { maintainServiceList: state.maintainServiceList.filter(({ id }) => id !== targetId) };
      }
      return {
        ...state,
        ...target,
      };
    },
    saveMaitainParameter(state, { payload }) {
      return {
        ...state,
        maintainParameter: payload,
      };
    },
  },
};
