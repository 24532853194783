/*
 * @Author: zhangtao272
 * @Date: 2020-05-29 15:18:27
 * @LastEditTime: 2022-04-16 11:38:40
 * @LastEditors: yanjinyu5
 * @Description: 
 * @FilePath: /Nirvana/src/services/layouts.js
 */
import request from '@/utils/request';

/**
 * @description: 首页权限
 */
export const getUserInfo = async (params) => {
  return request('/bumblebee/auth/menu', {
    method: 'POST',
    params,
  });
};

/**
 * 首页导航栏搜索信息
 * @param params 搜索参数
 */
export const getHeaderSearchInfo = async (params) => {
  return request('/gears/api/v1/cars/search', {
    method: 'GET',
    params,
  });
};

/**
 * 获取消息列表信息
 * @returns {Promise<any>}
 */
export const getRemindMessage = async () => {
  return request('/gears/api/v1/remind/message', {
    method: 'GET',
  });
};

/**
 * 切换版本
 * @returns {Promise<any>}
 */
export const configVersion = async () => {
  return request('/hound/api/v1/config/shop/version', {
    method: 'GET',
  });
};

/**
 * 侧边菜单京车会入口是否显示
 */
export const getShowJchRegister = async () => {
  return request('/hound/api/v1/config/shop/jchRegisterShow', {
    method: 'GET',
  });
};

/**
 * 京车会门店TAG
 */
export const getJCHShopTag = async () => {
  return request('/hound/api/v1/shop/tag', {
    method: 'GET',
  });
};

/**
 * @description 开关集合
 * 7项必填开关:高标店 OR 京车会门店
 * 引导建立京东账户开关
 */
export const getSwitch = () => {
  return request('/hound/api/v1/shop/order/createOrderSwitch', {
    method: 'GET',
  });
};
