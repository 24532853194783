import { message } from 'antd';
import {
  // getHeaderSearchInfo,
  getUserInfo,
  getRemindMessage,
  getShowJchRegister,
  getJCHShopTag,
  getSwitch,
} from '@/services/layouts';

const layouts = {
  namespace: 'layouts',
  state: {
    autoUserInfo: {}, // 用户信息
    authShopInfo: {}, // 门店信息
    fstListsY: [], // sidebar栏目权限
    fstListsX: [], // header栏目权限新
    remindMessage: [], // 提醒消息列表
    rules: [], // 栏目权限
    rulesButton: {}, // 页面按钮权限
    showJchRegister: false, // 侧边菜单京车会入口是否显示
    tagCodes: [], // 京车会门店标签
    requiredFieldSwitch: false, // 7项必填开关
    jdAccountGuideSwitch: false, // 引导建立京东账户开关
  },
  effects: {
    /**
     * @description: 导航栏搜索返回列表
     */
    // * getHeaderSearchInfoList({ payload }, { put, call }) {
    //   const res = yield call(getHeaderSearchInfo, payload);
    //   yield put({
    //     type: 'updateSearchInfoList',
    //     payload: res,
    //   });
    //   return res.data.searchInfoList;
    // },
    /**
     * 获取用户信息和权限
     */
    * getUserInfoList({ payload }, { put, call }) {
      const res = yield call(getUserInfo, payload);
      yield put({
        type: 'updateGetUser',
        payload: res,
      });
      const rules = [];
      const rulesButton = {};
      // 判断当前是不是高标店的用户
      const { data: { authShopInfo: { isWhite = false } } } = res;
      if (isWhite) {
        message.loading('请登录jch.yunxiu.com使用系统');
        setTimeout(() => {
          window.location.href = PAGE_URL;
        }, 1000);
      }
      res.data.fstListsY.forEach((item) => {
        rules.push(item.code);
        if (item.button) rulesButton[item.code] = item.button;
        if (item.secList) {
          item.secList.forEach((i) => {
            rules.push(i.code);
            if (i.button) rulesButton[i.code] = i.button;
            if (i.thirdList) {
              i.thirdList.forEach((l) => {
                rules.push(l.code);
                if (l.button) rulesButton[l.code] = l.button;
              });
            }
          });
        }
      });

      yield put({
        type: 'updateRules',
        payload: { rules, rulesButton },
      });

      return res.data;
    },
    /**
     * 获取消息信息
     */
    * getRemindMessageList({ payload }, { put, call }) {
      const res = yield call(getRemindMessage, payload);

      yield put({
        type: 'updateRemindMessage',
        payload: res,
      });
      return res.data;
    },
    /**
     * 侧边菜单京车会入口是否显示
     */
    * getShowJchRegisterFunc(_, { put, call }) {
      const res = yield call(getShowJchRegister);

      yield put({
        type: 'updateShowJchRegister',
        payload: res,
      });
    },
    /**
     * 获取京车会门店标签
     */
    * getJCHShopTag(_, { put, call }) {
      const { data } = yield call(getJCHShopTag);

      yield put({
        type: 'updateJCHShopTag',
        payload: data,
      });
    },
    /**
     * 获取开关
     */
    * getSwitch(_, { put, call }) {
      const { data } = yield call(getSwitch);

      yield put({
        type: 'updateSwitch',
        payload: data || {},
      });
    },
  },
  reducers: {
    /**
     * @description: 导航栏搜索跟新状态
     */
    // updateSearchInfoList(state, { payload }) {
    //   try {
    //     return {
    //       ...state,
    //       headerSearchInfoList: payload.data,
    //     };
    //   } catch (error) {
    //     // do nothing
    //     return state;
    //   }
    // },
    /**
     * 获取用户信息和权限
     */
    updateGetUser(state, { payload }) {
      try {
        const {
          authShopInfo,
          autoUserInfo,
          fstListsX,
          fstListsY,
        } = payload.data;

        return {
          ...state,
          authShopInfo,
          autoUserInfo: {
            ...autoUserInfo,
            userTitle: autoUserInfo.userName,
          },
          fstListsX,
          fstListsY,
        };
      } catch (e) {
        return state;
      }
    },
    /**
     * 获取消息信息
     */
    updateRemindMessage(state, { payload }) {
      try {
        const { remindMessage } = payload.data;
        return {
          ...state,
          remindMessage,
        };
      } catch (e) {
        return state;
      }
    },
    /**
     * 权限接口
     */
    updateRules(state, { payload }) {
      try {
        return {
          ...state,
          rules: payload.rules,
          rulesButton: payload.rulesButton,
        };
      } catch (e) {
        return state;
      }
    },
    /**
     * 侧边菜单京车会入口是否显示
     */
    updateShowJchRegister(state, { payload: { data: { showJchRegister } } }) {
      return {
        ...state,
        showJchRegister,
      };
    },
    /**
     * 更新京车会门店标签
     */
    updateJCHShopTag(state, { payload: { tagCodes = [] } }) {
      return {
        ...state,
        tagCodes,
      };
    },
    /**
     * 更新开关
     */
    updateSwitch(state, { payload: { createJdAccountGuide = false, createOrderRequiredField = false } }) {
      return {
        ...state,
        requiredFieldSwitch: createOrderRequiredField,
        jdAccountGuideSwitch: createJdAccountGuide,
      };
    },
  },
};

export default layouts;
