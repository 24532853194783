/*
 * @Author: hexujie
 * @Date: 2020-02-12 10:01:13
 * @LastEditTime: 2021-08-17 19:10:58
 * @LastEditors: zhangguijun8
 * @Description:
 * @FilePath: /Nirvana/src/utils/request.js
 */
/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { extend } from 'umi-request';
import { notification, message } from 'antd';
import cookies from './cookies';
// const codeMessage = {
//   200: '服务器成功返回请求的数据。',
//   201: '新建或修改数据成功。',
//   202: '一个请求已经进入后台排队（异步任务）。',
//   204: '删除数据成功。',
//   400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
//   401: '用户没有权限（令牌、用户名、密码错误）。',
//   403: '用户得到授权，但是访问是被禁止的。',
//   404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
//   406: '请求的格式不可得。',
//   410: '请求的资源被永久删除，且不会再得到的。',
//   422: '当创建一个对象时，发生一个验证错误。',
//   500: '服务器发生错误，请检查服务器。',
//   502: '网关错误。',
//   503: '服务不可用，服务器暂时过载或维护。',
//   504: '网关超时。',
// };
/**
 * 异常处理程序
 */

const errorHandler = async (error) => {
  const { response } = error;
  let res = { status: false };

  if (response && response.status) {
    res = await response.clone().json();
    message.error(res.error.msg);
  } else if (!response) {
    notification.error({
      description: '您的网络发生异常，无法连接服务器',
      message: '网络异常',
    });
  }

  return res;
};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
  // eslint-disable-next-line
  prefix: `${BASE_URL}`,
  errorHandler,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie
  isShowErrMsg: true, // 默认带错误时tip 提示
});

/**
 * 响应拦截
 */
request.interceptors.response.use(async (response, options) => {
  let res = null;
  const { isShowErrMsg } = options;
  try {
    res = await response.clone().json();
    if (res.status === false && isShowErrMsg) {
      message.error({
        content: res.error.msg,
        duration: 3,
      });
    }
  } catch (error) {
    // do nothing
  }
  // 只处理json格式数据， 原本是通过响应头的 'Content-Type' 去判断，但是有的响应没有设置这个字段
  if (res) {
    const errorCodeArray = [1007]; // 未登录状态码
    // 出现以上错误码时
    if (!res.status && errorCodeArray.includes(res.error.code)) {
      try {
        cookies.keys().forEach((key) => {
          const [, secondHost] = LEGEND_HOST.match(/^.+((\.[a-zA-Z\d-]+){2})$/);
          cookies.removeItem(key, '/', secondHost);
        });
      } catch (error) {
        throw new Error(error);
      }

      window.location.href = `${LEGEND_HOST}/legend/index`;
    }
  }
  return response;
}, { global: false });

export default request;
