/*
 * @Author: hexujie
 * @Date: 2020-06-23 16:53:30
 * @LastEditTime: 2020-08-18 17:30:55
 * @LastEditors: hexujie
 * @Description:
 * @FilePath: /Nirvana/src/pages/finance/orderPayment/service.js
 */
import request from '@/utils/request';

/**
 * @description: 工单详情
 */
export const orderDetail = async (params) => {
  return request('/megatron/api/v1/order/detail', {
    method: 'GET',
    params,
  });
};

/**
 * @description: 选择服务：搜索服务
 */
export const test = async (params) => {
  return request('/ark/api/v1/service/search', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 计算工单优惠信息 (快修快保单、综合维修单)
 */
export const getCouponList = async (params) => {
  return request('/gears/api/v1/order/discount', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 计算洗车单优惠
 */
export const getWashCouponList = async (params) => {
  return request('/gears/api/v1/order/carwash/discount', {
    method: 'POST',
    data: params,
  });
};

// TODO: 删除
// export const getWashCouponList = async (params) => {
//   return request('/legend/shop/settlement/debit/discount/discount-carwash-order', {
//     method: 'POST',
//     data: params,
//     prefix: 'https://yun.360cec.com',
//   });
// };
/**
 * @description: 工单坏账
 */
export const badBill = async (params) => {
  return request('/raiden/api/v1/debit/bad/bill', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 工单收款
 */
export const orderPay = async (params) => {
  return request('/raiden/api/v1/order/pay', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 工单列表查询
 */
export const getOrderList = async (params) => {
  return request('/megatron/api/v1/order/finance/list', {
    method: 'GET',
    params: {
      page: 1,
      size: 12,
      ...params,
    },
  });
};

/**
 * @description: 工单列表查询
 */
export const orderConfirm = async (params) => {
  return request('/megatron/api/v1/order/confirm', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description:
 * @param 是否可以支持使用其他用户的优惠
 * @return:
 */
export const customDiscount = async (params) => {
  return request('/hound/api/v1/setting/coupon/button', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 工单列表查询
 */
export const usedmembercard = async (params) => {
  return request('/gears/api/v1/order/usedmembercard', {
    method: 'GET',
    params,
  });
};

/**
 * @description: 使用其他客户卡卷发送验证码
 */
export const codeSend = async (params) => {
  return request('/gears/api/v1/customer/code/send', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 使用其他客户卡卷验证验证码
 */
export const codeCheck = async (params) => {
  return request('/gears/api/v1/customer/code/check', {
    method: 'POST',
    data: params,
  });
};
