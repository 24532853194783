/* eslint-disable no-unused-vars */
/*
 * @Author: wujing
 * @Date: 2020-02-21 16:59:04
 * @LastEditTime: 2021-01-12 15:32:25
 * @LastEditors: Please set LastEditors
 * @Description: 
 * @FilePath: /myapp/src/models/user.js
 */

const pageRecord = {
  namespace: 'pageRecord',
  state: {
    carSearch: {
      activeKey: '1',
      carSource: undefined,
      basicSearchVal: undefined,
      searchLicense: undefined,
      searchCustomerName: undefined,
      searchMobile: undefined,
      searchCompany: undefined,
      searchVin: undefined,
      searchCarInfo: undefined,
      searchTagName: undefined,
      searchSource: undefined,
      isJchArchivesSearch: undefined,
      searchTimeBetween: undefined,
      page: 1,
      searchStatus: false,
      searchMultStatus: false,
    },
    orderSearch: {
      keyword: undefined,
      orderSnLike: undefined,
      carLike: undefined,
      postscript: undefined,
      search_timeBetween: undefined,
      search_BussnessType: {
        id: undefined,
      },
      orderTag: undefined,
      orderStatuss: undefined,
      page: 1,
      searchStatus: false,
      orderTaglist: [],
    },
    paySearch: {
      keyword: undefined,
      search_timeBetween: undefined,
      search_timeBetween_debit: undefined,
      orderSnLike: undefined,
      companyLike: undefined,
      postscript: undefined,
      search_BussnessType: {
        id: undefined,
      },
      receiver: undefined,
      invoiceType: undefined,
      payStatus: undefined,
      page: 1,
      searchStatus: false,
    },
  },
  effects: {
  },
  reducers: {
    updateCarSearch(state, { payload }) {
      return {
        ...state,
        carSearch: {
          ...state.carSearch,
          ...payload,
        },
      };
    },
    updateOrderSearch(state, { payload }) {
      return {
        ...state,
        orderSearch: {
          ...state.orderSearch,
          ...payload,
        },
      };
    },
    updatePaySearch(state, { payload }) {
      return {
        ...state,
        paySearch: {
          ...state.paySearch,
          ...payload,
        },
      };
    },
  },
};
  
export default pageRecord;
  
