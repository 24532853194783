/*
 * @Author: hexujie
 * @Date: 2020-06-10 15:33:51
 * @LastEditTime: 2020-06-22 11:55:33
 * @LastEditors: hexujie
 * @Description: 
 * @FilePath: /Nirvana/src/pages/maintain/fastMaintain/Models/index.js
 */ 
import {
  managerList,
  searchConfigFields,
} from '../service';

const fastMaintain = {
  namespace: 'fastMaintain',
  state: {
    shopManagerVoListExsit: false,
    shopManagerVoList: [],
    serviceInfo: {
      serviceAmount: 0,
      discountAmount: 0,
    },
    goodsInfo: {
      goodsAmount: 0,
      goodsDiscountAmount: 0,
    },
    configFields: {},
  },
  effects: {
    // 服务顾问 销售员 维修工 采购人
    * getManagerList({ payload }, { call, put, select }) {
      const { fastMaintain: { shopManagerVoListExsit } } = yield select();
      if (shopManagerVoListExsit) {
        return;
      }
      yield put({
        type: 'setShopManagerVoListExsit',
      });
      const res = yield call(managerList, payload);
      yield put({
        type: 'saveManagerList',
        payload: res,
      });
    },
    // 必填项配置
    * getConfigFields(_, { call, put }) {
      const res = yield call(searchConfigFields);

      yield put({
        type: 'saveConfigFields',
        payload: res,
      });
    },
  },
  reducers: {
    // 更改服务费和优惠费
    updateServiceInfo(state, { payload }) {
      return {
        ...state,
        serviceInfo: {
          serviceAmount: payload.serviceAmount,
          discountAmount: payload.discountAmount,
        },
      };
    },
    // 更改配件费和优惠
    updateGoodsInfo(state, { payload }) {
      return {
        ...state,
        goodsInfo: {
          goodsAmount: payload.goodsAmount,
          goodsDiscountAmount: payload.discountAmount,
        },
      };
    },
    // 服务顾问 销售员 维修工 采购人
    saveManagerList(state, { payload }) {
      const { data } = payload;
      
      return {
        ...state,
        shopManagerVoList: data.shopManagerVoList || [],
      };
    },
    // 保存必填项配置
    saveConfigFields(state, { payload }) {
      const { data } = payload;
      
      return {
        ...state,
        configFields: { ...data } || {},
      };
    },
    // 保存必填项配置
    setShopManagerVoListExsit(state) {
      return {
        ...state,
        shopManagerVoListExsit: true,
      };
    },
  },
};

export default fastMaintain;
