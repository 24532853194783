/*
 * @Author: hexujie
 * @Date: 2020-03-18 10:38:19
 * @LastEditTime: 2020-05-09 18:55:33
 * @LastEditors: hexujie
 * @Description:
 */
/**
 * antd 风格全局配置
 */

export default {
  /**
   * theme for nav menu
   */
  navTheme: 'dark',
  /**
   * primary color of ant design
   */
  primaryColor: '#E63024',
  /**
   * nav menu position: `sidemenu` or `topmenu`
   */
  layout: 'sidemenu',
  /**
   * layout of content: `Fluid` or `Fixed`, only works when layout is topmenu
   */
  contentWidth: 'Fluid',
  /**
   * sticky header
   */
  fixedHeader: false,
  autoHideHeader: false,
  /**
   * sticky siderbar
   */
  fixSiderbar: false,
  colorWeak: false,
  menu: {
    locale: true,
  },
  title: '京东云修',
  pwa: false,
  iconfontUrl: null,
  // Your custom iconfont Symbol script Url
  // eg：//at.alicdn.com/t/font_1039637_btcrd5co4w.js
  // Usage: https://github.com/ant-design/ant-design-pro/pull/3517
};
