import { shopLocationRequest } from './service';

export default {
  namespace: 'carLicense',
  state: {
    shopLocation: undefined,
  },
  effects: {
    * getShopLoction(_, { call, put }) {
      const { data } = yield call(shopLocationRequest);
      yield put({
        type: 'setShopLoction',
        payload: {
          shopLocation: data,
        },
      });
    },
  },
  reducers: {
    setShopLoction(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
