/*
 * @Author: hexujie
 * @Date: 2020-02-11 16:19:01
 * @LastEditTime: 2020-05-09 20:31:15
 * @LastEditors: hexujie
 * @Description:
 * @FilePath: /myapp/src/models/setting.js
 */
import defaultSettings from '../../config/defaultSettings';

const updateColorWeak = (colorWeak) => {
  const root = document.getElementById('root');

  if (root) {
    root.className = colorWeak ? 'colorWeak' : '';
  }
};

const SettingModel = {
  namespace: 'settings',
  state: defaultSettings,
  reducers: {
    changeSetting(state = defaultSettings, { payload }) {
      const { colorWeak, contentWidth } = payload;

      if (state.contentWidth !== contentWidth && window.dispatchEvent) {
        window.dispatchEvent(new Event('resize'));
      }

      updateColorWeak(!!colorWeak);
      return {
        ...state,
        ...payload,
      };
    },
  },
};
export default SettingModel;
