/*
 * @Author: hexujie
 * @Date: 2020-02-21 16:59:04
 * @LastEditTime: 2020-09-18 20:13:51
 * @LastEditors: yangbing70
 * @Description:
 * @FilePath: /Nirvana/src/pages/home/models/home.js
 */
/* eslint-disable */
import {
  tagCount,
  notice,
  remind,
  installNowGuideCheck,
  consumeCodeCheck,
  hotCount,
  processingList,
  appointList,
  unconsumedList,
  statusList,
  saveAppoint,
  reservationList,
  customerService,
} from '../service';

const home = {
  namespace: 'home',
  state: {
    notice: {
      noticeTitle: '',
      noticeContent: '',
    },
    tagCount: {
      orderNumber: '',
      appointNumber: '',
      cousumeServiceNumber: '',
    },
    remind: {}, // 到期提醒弹窗
    installNowGuideCheck: {}, // 到期提醒弹窗
    homeOrderListStatus: [], // 未完结工单状态
    homeAppointListStatus: [], // 预约状态配置
    processingList: [], // 今日未完工单
    appointList: [], // 今日预约到店
    unconsumedList: [], // 待核销服务
    reservationList: [], // 预约单列表
    serviceInfoList: [], // 待核销服务列表
    acountBindingStatus: {}
  },
  effects: {
    /**
     * @description: 标签数量查询
     */
    * getTagCount({ payload }, { put, call }) {
      const res = yield call(tagCount, payload);

      yield put({
        type: 'updateTagCount',
        payload: res,
      });
    },
    /**
     * @description: 门店公告
     */
    * getNoticeContent({ payload }, { put, call }) {
      const res = yield call(notice, payload);
      yield put({
        type: 'updateNoticeContent',
        payload: res,
      });
      return res;
    },
    /**
     * @description: 门店到期提醒
     */
    * getRemindContent({ payload }, { put, call }) {
      const res = yield call(remind, payload);
      yield put({
        type: 'updateRemindContent',
        payload: res,
      });
      return res;
    },
    /**
     * @description: 马上装上线弹窗提示是否展示
     */
    * installNowGuideCheck({ payload }, { put, call }) {
      const res = yield call(installNowGuideCheck, payload);
      return res;
    },
    /**
     * @description:   立即注册弹窗
     */
     * acountBindingStatus({ payload }, { put, call }) {
     const res = yield call(acountBindingStatus, payload);
     return res;
   },
    /**
     * @description: 查询状态码列表
     */
    * getStatusList({ payload }, { put, call }) {
      const res = yield call(statusList, payload);
      yield put({
        type: 'updateStatusList',
        payload: res,
      });
    },
    /**
     * @description: 查询未完结工单
     */
    * getProcessingList({ payload }, { put, call }) {
      const res = yield call(processingList, payload);

      yield put({
        type: 'updateProcessingList',
        payload: res,
      });

      return res;
    },
    /**
     * @description: 查询今日预约到店
     */
    * getAppointList({ payload }, { put, call }) {
      const res = yield call(appointList, payload);

      yield put({
        type: 'updateAppointList',
        payload: res,
      });

      return res;
    },
    /**
     * @description: 查询今日预约到店下一页
     */
    * getNextAppointList({ payload }, { put, call }) {
      const res = yield call(appointList, payload);

      yield put({
        type: 'addNextAppointList',
        payload: res,
      });

      return res;
    },
    /**
     * @description: 查询待核销服务
     */
    * getUnconsumedList({ payload }, { put, call }) {
      const res = yield call(unconsumedList, payload);

      yield put({
        type: 'updateUnconsumedList',
        payload: res,
      });

      return res;
    },
    /**
     * @description: 查询预约单列表
     */
    * getReservationList({ payload }, { put, call }) {
      const res = yield call(reservationList, payload);

      yield put({
        type: 'updateReservationList',
        payload: res,
      });
    },
    /**
     * @description: 查询待核销服务
     */
    * getCustomerService({ payload }, { put, call }) {
      const res = yield call(customerService, payload);

      yield put({
        type: 'updateCustomerService',
        payload: res,
      });
    },
    /**
     * @description: 保存预约信息
     */
    * saveAppoint({ payload }, { put, call }) {
      const res = yield call(saveAppoint, payload);
      return res;
    },
  },
  reducers: {
    /**
     * @description: 标签数量查询
     */
    updateTagCount(state, { payload }) {
      const { content } = payload.data || {};

      return {
        ...state,
        tagCount: {
          ...content,
        },
      };
    },
    /**
     * @description: 门店公告
     */
    updateNoticeContent(state, { payload }) {
      const { noticeTitle, noticeContent, } = payload.data || {};

      return {
        ...state,
        notice: {
          noticeTitle,
          noticeContent,
        },
      };
    },
    /**
     * @description: 门店到期提醒
     */
    updateRemindContent(state, { payload }) {
      const content = payload.data || {};

      return {
        ...state,
        remind: {
          ...content,
        },
      };
    },
    /**
     * @description: 更新状态码列表
     */
    updateStatusList(state, { payload }) {
      try {
        const { content: { homeOrderListStatus, homeAppointListStatus } } = payload.data;

        return {
          ...state,
          homeOrderListStatus: homeOrderListStatus || [],
          homeAppointListStatus: homeAppointListStatus || [],
        };
      } catch (error) {
        // do nothing
        return state;
      }
    },
    /**
     * @description: 更新未完结工单
     */
    updateProcessingList(state, { payload }) {
      const { content, pageInfo } = payload.data || {};

      return {
        ...state,
        processingList: pageInfo.currentPage === 1 ? content : [...state.processingList, ...content],
      };
    },
    /**
     * @description: 更新今日预约到店
     */
    updateAppointList(state, { payload }) {
      const { content, pageInfo } = payload.data || {};

      return {
        ...state,
        appointList: pageInfo.currentPage === 1 ? content : [...state.appointList, ...content],
      };
    },
    /**
     * @description: 更新待核销服务
     */
    updateUnconsumedList(state, { payload }) {
      const { serviceInfoVo } = payload.data || {};

      return {
        ...state,
        unconsumedList: serviceInfoVo.pageInfo.currentPage === 1 ? serviceInfoVo.content : [...state.unconsumedList, ...serviceInfoVo.content],
      };
    },
    /**
     * @description: 更新预约单列表
     */
    updateReservationList(state, { payload }) {
      const { content } = payload.data || {};

      return {
        ...state,
        reservationList: content || [],
      };
    },
    /**
     * @description: 待核销服务内容列表
     */
    updateCustomerService(state, { payload }) {
      const { content } = payload.data || {};

      return {
        ...state,
        serviceInfoList: content || [],
      };
    },
  },
};

export default home;
