/*
 * @Author: hexujie
 * @Date: 2020-06-05 14:38:55
 * @LastEditTime: 2020-08-24 10:52:59
 * @LastEditors: hexujie
 * @Description:
 * @FilePath: /Nirvana/src/pages/maintain/fastMaintain/service.js
 */
import request from '@/utils/request';
// import requestX from '@/utils/requestX';

/**
 * @description: 选择自定服务：搜索服务
 */
export const shopServiceInfo = async (params) => {
  return request('/ark/api/v1/service/search', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 标准服务列表：搜索服务
 */
export const getStandardList = async (params) => {
  return request('/ark/api/v1/std/service/list', {
    method: 'GET',
    params,
  });
};

/**
 * @description: 选择标准配件
 */
export const selectedStandard = async (params) => {
  return request('/ark/api/v1/std/service/select', {
    method: 'GET',
    params,
  });
};
/**
 * @description: 选择标准配件
 */
export const getFormatServerType = async (params) => {
  return request('/ark/api/v1/cheetah/service/cates', {
    method: 'GET',
    params,
  });
};
/**
 * @description: 选择服务弹窗：查询服务类别
 */
export const tagCount = async (params) => {
  return request('/ark/shopServiceCate/getByName', {
    method: 'GET',
    params,
  });
};

/**
 * @description: 常规保养推荐弹框
 */
export const maintainRecommend = async (params) => {
  return request('/ark/api/v1/recommend/maintain', {
    method: 'GET',
    params,
  });
};

/**
 * @description: 选择服务弹窗：查询服务类别
 */
export const managerList = async (params) => {
  return request('/hound/api/v1/manager/list', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 新建配件资料的热门分类
 */
export const hotClassify = async (params) => {
  return request('/ark/api/v1/goods/category/hot/list', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 新建配件资料的自定义分类
 */
export const customClassify = async (params) => {
  return request('/ark/api/v1/goods/category/std/pid', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 新建配件资料的添加分类
 */
export const addClassify = async (params) => {
  return request('/ark/api/v1/goods/category/save', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 新建配件资料的查询分类
 */
export const searchClassify = async (params) => {
  return request('/ark/api/v1/goods/category/std/key', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 新建配件资料的适配车型
 */
export const searchCarModel = async (params) => {
  return request('/gears/api/v1/search/car', {
    method: 'GET',
    params,
  });
};

/**
 * @description: 选择配件：查询配件列表
 */
export const goodsList = async (params) => {
  return request('/ark/api/v1/goods/search', {
    method: 'POST',
    data: params,
  });
};
/**
 * @description: 选择附加：查询附加列表
 */
export const additionalList = async (params) => {
  return request('/ark/api/v1/service/extra/search', {
    method: 'POST',
    data: params,
  });
};
/**
 * @description: 导入入库单配件→根据入库单查询配件列表
 */
export const warehouseList = async (params) => {
  return request('/ark/api/v1/warehouse/in/order/list', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 新建配件资料的查询品牌
 */
export const searchBrand = async (params) => {
  return request('/ark/api/v1/goods/brand/list', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 新建配件资料的查询仓库货位
 */
export const searchDepotList = async (params) => {
  return request('/ark/api/v1/goods/depot/list', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 新建配件资料的配件单位
 */
export const searchByName = async (params) => {
  return request('/ark/api/v1/goods/unit/search', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 新建配件资料的表单提交
 */
export const goodsSubmit = async (params) => {
  return request('/ark/api/v1/goods/goods/save', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 新建配件资料的配件编号
 */
export const getPartsNumber = async (params) => {
  return request('/ark/api/v1/sn/generate', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 新建服务资料→查询服务类别
 */
export const searchCate = async (params) => {
  return request('/ark/api/v1/cate/search', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 根据vin查询车型
 */
export const carModelVin = async (params) => {
  return request('/gears/api/v1/carmodel/byvin', {
    method: 'GET',
    params,
  });
};

/**
 * @description: 保存快修快保、综合维修工单
 */
export const saveOrder = async (params) => {
  return request('/megatron/api/v1/order/save', {
    method: 'POST',
    data: params,
    isShowErrMsg: false,
  });
};

/**
 * @description: 根据vin查询车辆信息是否存在
 */
export const customerCarVin = async (params) => {
  return request('/gears/api/v1/customercar/byvin', {
    method: 'GET',
    params,
  });
};

/**
 * @description: 快修快保收款
 */
export const saveCollect = async (params) => {
  return request('/megatron/api/v1/order/save/collect', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: vin码查询
 */
export const searchVin = async (vin) => {
  return request('/gears/api/v1/customercar/byvin', {
    method: 'GET',
    params: {
      vin,
    },
  });
};

/**
 * @description: 查询表单必填项配置
 */
export const searchConfigFields = async () => {
  return request('/hound/api/v1/config/other/list', {
    method: 'GET',
  });
};

/**
 * @description: 保存子单
 */
export const virtualSave = async (params) => {
  return request('/megatron/api/v1/order/virtual/save', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 删除子单
 */
export const virtualDelete = async (params) => {
  return request('/megatron/api/v1/order/virtual/delete', {
    method: 'GET',
    params,
  });
};

/**
 * @description: 查询子单
 */
export const virtualGet = async (params) => {
  return request('/megatron/api/v1/order/virtual/get', {
    method: 'GET',
    params,
  });
};

/**
 * @description: 打印子单
 */
export const virtualPrint = async (params) => {
  return request('/megatron/api/v1/order/virtual/print', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 根据服务查配件
 */
export const getGoodsByService = async (params) => {
  return request('/ark/api/v1/goods/service/id', {
    method: 'POST',
    data: params,
  });
};

/**
 * @description: 是否填写故障描述
 */
export const isDisplayFaultDescription = async (params) => {
  return request('/megatron/api/v1/order/archives/isDisplayFaultDescription', {
    method: 'GET',
    params,
  });
};

/**
 * @description: 获取门店标签
 */
export const shopTag = async (params) => {
  return request('/hound/api/v1/shop/tag', {
    method: 'GET',
    data: params,
  });
};

/**
 * @description: 获取全部汽车品牌
 */
export const getCarBrand = async (params) => {
  return request('/ark/api/v1/jaguar/car/brand/list', {
    method: 'GET',
    params,
  });
};

/**
 * @description: 获取某一品牌下厂商
 */
export const getCarFactory = async (params) => {
  return request('/ark/api/v1/jaguar/car/factory/list', {
    method: 'GET',
    params,
  });
};

/**
 * @description: 获取某一厂商下的车系
 */
export const getCarSeries = async (params) => {
  return request('/ark/api/v1/jaguar/car/series/list', {
    method: 'GET',
    params,
  });
};

/**
 * @description: 获取某一车系下的年款
 */
export const getCarYear = async (params) => {
  return request('/ark/api/v1/jaguar/car/year/list', {
    method: 'GET',
    params,
  });
};

/**
 * @description: 获取某一年款下的销售版本
 */
export const getCarInfo = async (params) => {
  return request('/ark/api/v1/jaguar/car/std/list', {
    method: 'GET',
    params,
  });
};

/**
 * @description: 查询标准配件
 */
export const getFormatGooods = async (params) => {
  return request('/ark/api/v1/std/goods/list', {
    method: 'GET',
    params,
  });
};

// /**
//  * @description: 查询标准配件 调试！！！
//  */
// export const getFormatGooods = async (params) => {
//   // return request('/ark/api/v1/std/goods/list', {
//   return requestX('http://localhost:3000/searchList', {
//     method: 'GET',
//     params,
//   });
// };

/**
 * @description: 标准配件选择
 */
export const getChoseFormatGoods = async (params) => {
  return request('/ark/api/v1/std/goods/select', {
    method: 'GET',
    params,
  });
};
