/* eslint-disable no-unused-vars */
/*
 * @Author: hexujie
 * @Date: 2020-02-21 16:59:04
 * @LastEditTime: 2020-05-09 20:30:03
 * @LastEditors: hexujie
 * @Description: 
 * @FilePath: /myapp/src/models/user.js
 */

const userInfo = {
  namespace: 'userInfo',
  state: {
  },
  effects: {
  },
  reducers: {
  },
};

export default userInfo;
