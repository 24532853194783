/*
 * @Author: hexujie
 * @Date: 2020-07-16 14:55:38
 * @LastEditTime: 2020-07-16 14:55:53
 * @LastEditors: hexujie
 * @Description:
 * @FilePath: /Nirvana/src/components/PageLoading/index.js
 */
import React from 'react';
import { Spin } from 'antd';
import styles from './index.less';

export default () => {
  return (
    <div className={styles.cpPageLoading}>
      <Spin size="large" />
    </div>
  );
};
