// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from '/Users/yangbing70/gitWorkspace/Nirvana/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import ModelGlobal0 from '/Users/yangbing70/gitWorkspace/Nirvana/src/models/global.js';
import ModelLayouts1 from '/Users/yangbing70/gitWorkspace/Nirvana/src/models/layouts.js';
import ModelPageRecord2 from '/Users/yangbing70/gitWorkspace/Nirvana/src/models/pageRecord.js';
import ModelSetting3 from '/Users/yangbing70/gitWorkspace/Nirvana/src/models/setting.js';
import ModelUser4 from '/Users/yangbing70/gitWorkspace/Nirvana/src/models/user.js';
import ModelPay5 from '/Users/yangbing70/gitWorkspace/Nirvana/src/pages/finance/orderPayment/models/pay.js';
import ModelHome6 from '/Users/yangbing70/gitWorkspace/Nirvana/src/pages/home/models/home.js';
import ModelIndex7 from '/Users/yangbing70/gitWorkspace/Nirvana/src/pages/maintain/comprehensiveMaintain/models/index.js';
import ModelIndex8 from '/Users/yangbing70/gitWorkspace/Nirvana/src/pages/maintain/fastMaintain/models/index.js';
import ModelModel9 from '/Users/yangbing70/gitWorkspace/Nirvana/src/pages/maintain/carSearch/model.js';
import ModelModel10 from '/Users/yangbing70/gitWorkspace/Nirvana/src/pages/maintain/components/CarLincenceInput/model.js';
import dvaImmer, { enableES5, enableAllPlugins } from '/Users/yangbing70/gitWorkspace/Nirvana/node_modules/dva-immer/dist/index.js';

let app:any = null;

export function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  app.use(dvaImmer());
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'global', ...ModelGlobal0 });
app.model({ namespace: 'layouts', ...ModelLayouts1 });
app.model({ namespace: 'pageRecord', ...ModelPageRecord2 });
app.model({ namespace: 'setting', ...ModelSetting3 });
app.model({ namespace: 'user', ...ModelUser4 });
app.model({ namespace: 'pay', ...ModelPay5 });
app.model({ namespace: 'home', ...ModelHome6 });
app.model({ namespace: 'index', ...ModelIndex7 });
app.model({ namespace: 'index', ...ModelIndex8 });
app.model({ namespace: 'model', ...ModelModel9 });
app.model({ namespace: 'model', ...ModelModel10 });
  return app;
}

export function getApp() {
  return app;
}

/**
 * whether browser env
 * 
 * @returns boolean
 */
function isBrowser(): boolean {
  return typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (isBrowser()) {
      _onCreate()
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    let app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
