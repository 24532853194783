// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/Users/yangbing70/gitWorkspace/Nirvana/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/login",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'login' */'/Users/yangbing70/gitWorkspace/Nirvana/src/login'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/login",
        "name": "登录",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'login__login' */'/Users/yangbing70/gitWorkspace/Nirvana/src/login/login'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/login/register",
        "name": "认证",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'login__yxRegister' */'/Users/yangbing70/gitWorkspace/Nirvana/src/login/yxRegister'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/login/wait",
        "name": "认证中",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'login__wait' */'/Users/yangbing70/gitWorkspace/Nirvana/src/login/wait'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'login__login' */'/Users/yangbing70/gitWorkspace/Nirvana/src/login/login'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/pivot",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'login__pivot' */'/Users/yangbing70/gitWorkspace/Nirvana/src/login/pivot'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/register",
    "name": "京东注册",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'login__register' */'/Users/yangbing70/gitWorkspace/Nirvana/src/login/register'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/Users/yangbing70/gitWorkspace/Nirvana/src/layouts/BasicLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "name": "首页",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home__index' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/home/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/reception",
        "name": "接车维修",
        "routes": [
          {
            "path": "/reception/fast",
            "name": "新建快修快报单",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__maintain__fastMaintain__index' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/maintain/fastMaintain/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/reception/comprehensive",
            "name": "新建综合维修单",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__maintain__comprehensiveMaintain__index' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/maintain/comprehensiveMaintain/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/reception/order/list",
            "name": "工单查询-工单列表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__maintain__orderSearch__list' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/maintain/orderSearch/list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/reception/order/detail/fast",
            "name": "快修快保-工单查询详情",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__maintain__orderSearch__orderDetail' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/maintain/orderSearch/orderDetail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/reception/order/detail/comprehensive",
            "name": "综合维修-工单查询详情",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__maintain__orderComprehensive__orderDetail' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/maintain/orderComprehensive/orderDetail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/reception/order/detail/orderImportedJobs",
            "name": "导入的工单-工单详情",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__maintain__orderImportedJobs__orderDetail' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/maintain/orderImportedJobs/orderDetail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/reception/carSearch/list",
            "name": "车辆查询",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__maintain__carSearch__list' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/maintain/carSearch/list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/reception/car/detail",
            "name": "车辆详情",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__maintain__carSearch__carDetail' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/maintain/carSearch/carDetail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/reception/newCar",
            "name": "新建车辆",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__maintain__newCar__newCar' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/maintain/newCar/newCar'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/reception/order/detail/carWash",
            "name": "洗车单-工单详情",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__maintain__orderCarwash__orderDetail' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/maintain/orderCarwash/orderDetail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/reception/wash",
            "name": "新建洗车单",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__maintain__carWash__index' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/maintain/carWash/index'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/settlement",
        "name": "财务",
        "routes": [
          {
            "path": "/settlement/order/list",
            "name": "工单收款-工单列表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__finance__orderPayment__list' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/finance/orderPayment/list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/settlement/order/detail",
            "name": "工单收款-工单详情",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__finance__orderPayment__orderDetail' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/finance/orderPayment/orderDetail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/settlement/order/detail/orderImportedJobs",
            "name": "导入的工单-工单详情",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__maintain__orderImportedJobs__orderDetail' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/maintain/orderImportedJobs/orderDetail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/settlement/order/pay",
            "name": "工单收款",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__finance__orderPayment__pay' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/finance/orderPayment/pay'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/settlement/order/detail/fast",
            "name": "快修快保-收款详情",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__maintain__orderSearch__orderDetail' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/maintain/orderSearch/orderDetail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/settlement/order/detail/comprehensive",
            "name": "综合维修-收款详情",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__maintain__orderComprehensive__orderDetail' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/maintain/orderComprehensive/orderDetail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/settlement/order/detail/carWash",
            "name": "洗车单-工单详情",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__maintain__orderCarwash__orderDetail' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/maintain/orderCarwash/orderDetail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/settlement/reconciliation/serverviceFee",
            "name": "对账-服务费对账明细",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__maintain__reconciliation__serverviceFee' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/maintain/reconciliation/serverviceFee'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/activity",
        "name": "引流活动",
        "routes": [
          {
            "path": "/activity/install/chargeoff",
            "name": "京东商城马上装-马上装快速核销",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activity__installNow__chargeOff__index' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/activity/installNow/chargeOff/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/activity/installNow/goodsManager",
            "name": "京东商城马上装-售卖商品管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activity__installNow__goodsManager' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/activity/installNow/goodsManager'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/activity/installNow/shoppingCode",
            "name": "京东商城马上装-商城验证码查询",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activity__installNow__shoppingCode__' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/activity/installNow/shoppingCode/'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/activity/installNow/physicalSettlement/index",
            "name": "京东商城马上装-实物结算单列表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activity__installNow__physicalSettlement__index' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/activity/installNow/physicalSettlement/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/activity/installNow/physicalSettlement/detail",
            "name": "京东商城马上装-实物结算单详情",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activity__installNow__physicalSettlement__detail' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/activity/installNow/physicalSettlement/detail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/activity/installNow/physicalSettlement/drainageDetail",
            "name": "京东商城马上装-实物结算单引流详情",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activity__installNow__physicalSettlement__drainageDetail' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/activity/installNow/physicalSettlement/drainageDetail'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/yangbing70/gitWorkspace/Nirvana/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
