/*
 * @Author: hexujie
 * @Date: 2020-06-10 15:33:51
 * @LastEditTime: 2020-07-11 13:57:02
 * @LastEditors: hexujie
 * @Description: 
 * @FilePath: /Nirvana/src/pages/maintain/comprehensiveMaintain/models/index.js
 */ 
// import {
//   managerList,
// } from '../service';

const comprehensiveMaintain = {
  namespace: 'comprehensiveMaintain',
  state: {
    shopManagerVoList: [],
    serviceInfo: {
      serviceAmount: 0,
      discountAmount: 0,
    },
    goodsInfo: {
      goodsAmount: 0,
      goodsDiscountAmount: 0,
    },
    additionalInfo: {
      additionalAmount: 0,
      additionalDiscountAmount: 0,
    },
  },
  effects: {
    // 服务顾问 销售员 维修工 采购人
    // * getManagerList({ payload }, { call, put }) {
    //   const res = yield call(managerList, payload);

    //   yield put({
    //     type: 'saveManagerList',
    //     payload: res,
    //   });
    // },
  },
  reducers: {
    // 更改服务费和优惠费
    updateServiceInfo(state, { payload }) {
      return {
        ...state,
        serviceInfo: {
          serviceAmount: payload.serviceAmount,
          discountAmount: payload.discountAmount,
        },
      };
    },
    // 更改配件费和优惠
    updateGoodsInfo(state, { payload }) {
      return {
        ...state,
        goodsInfo: {
          goodsAmount: payload.goodsAmount,
          goodsDiscountAmount: payload.discountAmount,
        },
      };
    },
    // 更改附加费费和优惠
    updateAdditionalInfo(state, { payload }) {
      return {
        ...state,
        additionalInfo: {
          additionalAmount: payload.additionalAmount,
          additionalDiscountAmount: payload.discountAmount,
        },
      };
    },
    // 服务顾问 销售员 维修工 采购人
    saveManagerList(state, { payload }) {
      const { data } = payload;
      
      return {
        ...state,
        shopManagerVoList: data.shopManagerVoList || [],
      };
    },
  },
};

export default comprehensiveMaintain;
