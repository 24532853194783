/*
 * @Author: hexujie
 * @Date: 2020-02-21 16:59:04
 * @LastEditTime: 2020-08-31 17:31:15
 * @LastEditors: hexujie
 * @Description:
 * 由于项目中有大量统计图，为了数据的展示效果，小屏幕的布局和大屏幕的布局需要单独处理，
 *
 * @FilePath: /Nirvana/src/models/global.js
 */

const global = {
  namespace: 'global',
  state: {
    isShowMsgTip: false, // 是否展示第一次访问的消息提示框
    isSmallScreen: false, // 是否是最小适配屏幕
  },
  effects: {
    // * setScreenSize({ payload }, { put }) {
    //   yield put({
    //     type: 'setScrennSize',
    //     payload,
    //   });
    // },
  },
  reducers: {
    setScreenSize(state, { payload }) {
      return {
        ...state,
        isSmallScreen: payload.isSmallScreen,
      };
    },
    updateIsShowMsgTip(state, { payload }) {
      return {
        ...state,
        isShowMsgTip: payload,
      };
    },
  },
};

export default global;
