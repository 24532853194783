import request from '@/utils/request';

/**
 * @description: 模糊查询车牌列表
 */
export const carLinsenceListRequest = async (value) => {
  return request('/gears/api/v1/cars/license', {
    method: 'GET',
    params: { carLicense: value },
  });
};

/**
 * @description: 查询车型详情
 */
export const carDetailRequest = async (params) => {
  return request('/gears/api/v1/cars/id', {
    method: 'GET',
    params,
  });
};

/**
 * @description: 查询提醒信息
 */
export const remindInfoRequest = async (params) => {
  return request('/megatron/api/v1/order/checkremind', {
    method: 'GET',
    params,
  });
};

/**
 * @description: 保存提醒设置
 */
export const saveRemindSettingsRequest = async (params) => {
  return request('/megatron/api/v1/order/saveremind', {
    method: 'GET',
    params,
  });
};

/**
 * @description: 查询门店定位车牌前两位
 */
export const shopLocationRequest = async () => {
  return request('/gears/api/v1/customercar/licenseshop', {
    method: 'GET',
  });
};
